import React from "react";
import SEO from "../components/SEO/seo";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import Pagebuilder from "../components/pagebuilder";

const IndexPage = ({ data }) => {
  return (
    <Layout className="home" dark={true}>
      <SEO
        pageTitle={data.home.frontmatter.title}
        pageDescription={false}
        pageImage={false}
        url={""}
      />
      <Pagebuilder blocks={data.home.frontmatter.content_blocks} />
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    home: markdownRemark(frontmatter: { path: { eq: "/home/" } }) {
      html
      frontmatter {
        title
        metaDescription
        # metaImage {
        #   relativePath
        #   childImageSharp {
        #     fluid(maxWidth: 1000, quality: 69) {
        #       ...GatsbyImageSharpFluid_withWebp
        #     }
        #   }
        # }
        content_blocks {
          type
          header
          intro
          # cta_text
          # cta_link
          # cta_1_text
          # cta_1_link
          # cta_2_text
          # cta_2_link
          # cta_3_text
          # cta_3_link
          # image_leftright
          # text
          title
          work
          image {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1000, quality: 69) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        thumbnail {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1000, quality: 69) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
